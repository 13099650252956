import React from 'react';
import { TablePageButtons } from './table-page-buttons';
import { GQL } from 'market-dto';

interface Props {
    readonly filterText:string;
    readonly setFilterText:(x:string) => void;
    readonly totalPages:number;
    readonly page:number;
    readonly setPage:(x:number) => void;
    readonly buttons:number[];
    readonly showingCount:number;
    readonly totalItemCount:number;
    readonly listName:string;
    readonly lists?:GQL.Checklist[];
    readonly onChangeList?:(x:GQL.Checklist) => void;
}
export const ChecklistFilterButtons = ({
    filterText,
    setFilterText,
    buttons,
    totalPages,
    page,
    setPage,
    showingCount,
    totalItemCount,
    listName,
    lists,
    onChangeList
}:Props) => {

    const showDropdown = lists && lists.length > 1;

    return (
        <div className="rxp-flex-between">
            <div className="rxp-flex-vcenter-only">
                <input
                    type="text"
                    className="rxp-input"
                    placeholder="Filter rules"
                    autoFocus
                    value={filterText}
                    onChange={x => setFilterText(x.target.value)}
                />
                <TablePageButtons
                    buttons={buttons}
                    page={page}
                    setPage={setPage}
                    totalPages={totalPages}
                />
                <div style={{ marginLeft:'8px', fontSize:'14px'}}>
                    { showingCount }
                    <span className="rxp-faded">&nbsp;of&nbsp;</span>
                    { totalItemCount }
                </div>
            </div>
            { showDropdown && lists && (
                <select className="rxp-dropdown" onChange={x => {
                    const index = Number.parseInt(x.target.value);
                    if (onChangeList) onChangeList(lists[index]);
                }}>
                    { lists.map((x, n) => (
                        <option value={n}>{ x.name }</option>
                    )) }
                </select>
            ) }
            { !showDropdown && (
                <div className="rxp-checklist-name">{ listName }</div>
            ) }
            
        </div>
    )
}