// actual store funcs/hooks are in mui.
// This just defines the keys (enums) and the typings for each store.
// be careful: the string values go into localStorage as keys

export enum keys {
    serviceDiscovery = "srv-dsc",
    doNotUse = "do-not-use"             // just me testing
}

export type IdProvider = "BOTH"|"SELF"|"AZURE";

export interface ServiceDiscovery {
    readonly apiUrl:string;
    readonly azureSignInUrl:string;
    readonly idProvider:IdProvider;
}
