import React from 'react';
import { Box } from 'mui';
import { Logo } from 'components';

export const NotFound = () => {
    return (
        <Box mt="2">
            <Box mx="auto" width="card-lg" bg="card" p="2" roundedEdges="all" borderColor="alert" borderSides="all">

                <Logo />

                <Box fg="loud" fontSize="lg" mt="2">Welcome to marketLens!</Box>
                <Box fg="default" mt="2">
                    Check your querystring.
                    <br/>
                    I'm not entirely sure what you're looking for.
                </Box>
            </Box>
        </Box>
    )
}