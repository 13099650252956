import React from 'react';
import { Box } from 'mui';
import { Logo } from 'components';

interface Props {
    readonly err:string;
}
export const Error = ({ err }:Props) => {
    return (
        <Box mt="2">

            <Box mx="auto" width="card-lg" bg="card" p="2" roundedEdges="all" borderColor="alert" borderSides="all">

                <Logo />

                <Box fg="loud" fontSize="lg" mt="2">Uh oh!</Box>
                <Box fg="default" mt="2">
                    Check your querystring. I'm not entirely sure what you're looking for.
                </Box>

                <Box fg="faded" mt="2">Details:</Box>
                <Box fg="default" mt="1" mono>
                    { err }
                </Box>
            </Box>

        </Box>
    )
}