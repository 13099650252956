import { ApolloClient, InMemoryCache } from "@apollo/client"
import { getStore } from 'mui';

export const getApolloClient = (bearerToken:string) => {

	const { apiUrl } = getStore("srv-dsc");

	return new ApolloClient({
		// uri: urls.getApiUrl(),
		// uri: apiUrl,
		uri: apiUrl,
		headers: {
			'Authorization': 'Bearer ' +  bearerToken
		},
		cache: new InMemoryCache(),
		// request: (operation) => {
		// 	operation.setContext({
		// 		headers: {
		// 			'Authorization': 'Bearer ' +  token
		// 		}
		// 	})
		// }
	})
}