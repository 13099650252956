import React from 'react';

interface Props {
    readonly amountProductTypes:number;
    readonly tab:"hardstop"|"product"|"program";
    readonly setTab:(x:"hardstop"|"product"|"program") => void;
}
export const ChecklistTabs = ({ tab, setTab, amountProductTypes }:Props) => {
    return (
        <div className="rxp-tabs">
            <div
                className={tab === 'hardstop' ? 'rxp-active-tab' : 'rxp-tab'}
                onClick={() => setTab('hardstop')}
            >Hard Stop</div>
            <div
                className={tab === 'program' ? 'rxp-active-tab' : 'rxp-tab'}
                onClick={() => setTab('program')}
            >Product Group</div>
            <div
                className={tab === 'product' ? 'rxp-active-tab' : 'rxp-tab'}
                onClick={() => setTab('product')}
            >Product Type{ amountProductTypes > 1 ? 's' : '' }</div>
        </div>
    )
}