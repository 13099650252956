import React from 'react';

interface Props {
    readonly totalPages:number;
    readonly page:number;
    readonly setPage:(x:number) => void;
    readonly buttons:number[];
}
export const TablePageButtons = ({ totalPages, buttons, page, setPage }:Props) => {

    if (totalPages < 2) return null;

    return (
        <div className="rxp-button-group">
            { buttons[0] > 0 && (
                <button className="rxp-page-button" onClick={() => setPage(0)}>«</button>
            )}
            { buttons && buttons.map((b, n) => (
                <button
                    key={n}
                    className={page === b ? 'rxp-page-button rxp-page-button-active' : 'rxp-page-button'}
                    onClick={() => setPage(b)}
                >{b+1}</button>
            ))}
            { buttons[buttons.length-1] < totalPages-1 && (
                <button className="rxp-page-button" onClick={() => setPage(totalPages-1)}>»</button>
            )}
        </div>
    )
}