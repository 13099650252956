import React, { useState, useEffect } from 'react';
import { ExtendedCheckItem } from './types';
import { usePageSortTable, Exp } from 'mui';
import { ChecklistFilterButtons } from './checklist-filter-buttons';
import { RuleTable } from './rule-table';
import { GQL } from 'market-dto';

const getFilteredItems = (items:ExtendedCheckItem[], filterText:string):ExtendedCheckItem[] => {
    const trimmed = filterText.trim().toLowerCase();
    if (!trimmed) return items;
    return items.filter(item => {
        if (item.id.toLowerCase().includes(trimmed)) return true;
        if (item.expressions.some(x => x.toLowerCase().includes(trimmed))) return true;
        if (item.preconditions && item.preconditions.some(x => x.toLowerCase().includes(trimmed))) return true;
        if (item.conclusion && Object.entries(item.conclusion).some(([ key, val ]) => {
            if (key.trim().toLowerCase().includes(trimmed)) return true;
            if (String(val).trim().toLowerCase().includes(trimmed)) return true;
        })) return true;
        if (item.elseConclusion && Object.entries(item.elseConclusion).some(([ key, val ]) => {
            if (key.toLowerCase().includes(trimmed)) return true;
            if (String(val).toLowerCase().includes(trimmed)) return true;
        })) return true;
        return false;
    })
}


interface Props {
    readonly expCtx:Exp.ExpContext;
    readonly list:GQL.Checklist;
    readonly applyModelLabels:boolean;
    // if you are product type, you get an array and can change the current one.
    readonly lists?:GQL.Checklist[];
    readonly onChangeList?:(x:GQL.Checklist) => void;
}

export const ChecklistViewer = ({
    expCtx,
    list,
    applyModelLabels,
    lists,
    onChangeList
}:Props) => {

    const getAllItemsWithOrderNo = () => {
        return list.items.map((item, n) => {
            return {
                ...item,
                orderNo: n
            }
        })
    }

    const [ filterText, setFilterText ] = useState('');    
    const [ allItems, setAllItems ] = useState<ExtendedCheckItem[]>(getAllItemsWithOrderNo);
    const [ filteredItems, setFilteredItems ] = useState<ExtendedCheckItem[]>(() => getFilteredItems(getAllItemsWithOrderNo(), filterText));

    useEffect(() => {
        const allItemsWithOrderNo = getAllItemsWithOrderNo();
        setAllItems(allItemsWithOrderNo);
        setFilteredItems(getFilteredItems(allItemsWithOrderNo, filterText));
    }, [list.id])

    useEffect(() => {
        const trimmed = filterText.trim().toLowerCase();
        if (!trimmed) {
            setFilteredItems(allItems)
            return;
        }
        const filteredItems = allItems.filter(item => {
            if (item.id.toLowerCase().includes(trimmed)) return true;
            if (item.description?.toLowerCase().includes(trimmed)) return true;
            if (item.expressions.some(x => x.toLowerCase().includes(trimmed))) return true;
            if (item.preconditions && item.preconditions.some(x => x.toLowerCase().includes(trimmed))) return true;
            if (item.conclusion && Object.entries(item.conclusion).some(([ key, val ]) => {
                if (key.trim().toLowerCase().includes(trimmed)) return true;
                if (String(val).trim().toLowerCase().includes(trimmed)) return true;
            })) return true;
            if (item.elseConclusion && Object.entries(item.elseConclusion).some(([ key, val ]) => {
                if (key.toLowerCase().includes(trimmed)) return true;
                if (String(val).toLowerCase().includes(trimmed)) return true;
            })) return true;
            return false;
        })
        setFilteredItems(filteredItems);
    }, [filterText])

    const {
        buttons,
        page,
        totalPages,
        setPage,
        sortedRows
    } = usePageSortTable<ExtendedCheckItem, keyof ExtendedCheckItem>({
        rows: filteredItems,
        keyFn: row => list.id + ':' + row.orderNo,
        valFn: (row, colId) => row[colId],
        paging: {
            maxButtons: 9,
            maxRowsPerPage: 20
        }
    })


    return (
        <>
            <ChecklistFilterButtons
                filterText={filterText}
                setFilterText={setFilterText}
                buttons={buttons}
                page={page}
                setPage={setPage}
                showingCount={filteredItems.length}
                totalItemCount={allItems.length}
                totalPages={totalPages}
                listName={list.name}
                lists={lists}
                onChangeList={onChangeList}
            />

            <RuleTable
                expCtx={expCtx}
                items={sortedRows}
                filterText={filterText}
                applyModelLabels={applyModelLabels}
            />

        </>
    )
}