import React, { useState, useEffect } from 'react';
import { Box, SpinLabel, useViewport, usePrevious, Exp } from 'mui';
import { handshakeChecklist } from 'api';
import { ChecklistsAndModel } from './types';
import { ChecklistViewer } from './checklist-viewer';
import { ChecklistTabs } from './checklist-tabs';
import { Logo }  from 'components';
import { exportListsAsCsv } from './export-lists-as-csv';
import { ChecklistDetails } from './checklist-details';
import { TopRightBtnArea } from './top-right-btn-area';
import { PrintView } from './print-view';
import { GQL } from 'market-dto';
import './viewer.css';

interface Props {
    readonly token:string;
}

export const ChecklistViewerContainer = ({ token }:Props) => {

    // Let's simulate loading multiple checklists
    // here we will grab token from querystring!

    const [ data, setData ] = useState<ChecklistsAndModel>();
    const [ err, setErr ] = useState('');
    const [ fetching, setFetching ] = useState(true);
    const [ tab, setTab ] = useState<"hardstop"|"program"|"product">("hardstop");
    const [ currList, setCurrList ] = useState<GQL.Checklist>();
    const [ printing, setPrinting ] = useState(false);
    const [ applyModelLabels, setApplyModelLabels ] = useState(false);

	const doHandhsake = async (token:string) => {		
		const result = await handshakeChecklist(token);
        if (!result) {
            setErr('Handshake failed.');
        } else {
            try {
                const expCtx = Exp.flatJsonSchemaToExpCtx(result.model.schema, true);
                setData({
                    ...result,
                    expCtx
                })
            } catch {
                setErr('Invalid checklist and/or model json.');
            }

        }
        setFetching(false);
	}

    useEffect(() => {
        // We are using this one web app as multiple web apps...but we already styles applied at this point.
        // So...we set the data-app attribute which changes us to the checklist viewer background.
        document.body.setAttribute('data-app', 'checklist');
        doHandhsake(token);
    }, [token])

    useEffect(() => {
        if (!data) return;
        if (tab === "hardstop") {
            setCurrList(data.hardstop);
        } else if (tab === "program") {
            setCurrList(data.programChecklist);
        } else {
            setCurrList(data.productTypeChecklists[0]);
        }        
    }, [tab, data])

    useEffect(() => {
        if (printing) {
            setTimeout(() => {
                window.print();
                setPrinting(false);
            }, 0)
        }
    }, [printing])

    return (
        <div className="checklist-container">
            <div className="rxp-main">

                <div className="rxp-print-hide" style={{ marginBottom:'8px' }}>
                    <div className="rxp-flex" style={{ justifyContent:'space-between' }}>
                        <Logo />
                        { data && (
                            <div>                            
                                <TopRightBtnArea
                                    applyModelLabels={applyModelLabels}
                                    setApplyModelLabels={setApplyModelLabels}
                                    onExportCsv={() => exportListsAsCsv(data, applyModelLabels)}
                                    onPrint={() => setPrinting(true)}
                                />
                            </div>
                        ) }
                    </div>
                </div>

                { fetching && (
                    <div style={{ margin: '8px' }}>
                        <SpinLabel>Loading...</SpinLabel>
                    </div>
                ) }

                { data && (
                    <>                  
                        { printing && (
                            <div className="rxp-print-only">
                                <ChecklistDetails keyValuePairs={data.displayParams} />
                                <PrintView
                                    expCtx={data.expCtx}
                                    label="Hard Stop"
                                    list={data.hardstop}
                                    applyModelLabels={applyModelLabels}
                                />
                                <PrintView
                                    expCtx={data.expCtx}
                                    label="Product Group"
                                    list={data.programChecklist}
                                    applyModelLabels={applyModelLabels}
                                />
                                { data.productTypeChecklists.map(list => (
                                    <PrintView
                                        expCtx={data.expCtx}
                                        label="Product Type"
                                        list={list}
                                        applyModelLabels={applyModelLabels}
                                    />
                                )) }
                            </div>
                        ) }
                        { currList && (
                            <div className="rxp-print-hide" style={{ marginTop: '8px' }}>
                                <ChecklistDetails keyValuePairs={data.displayParams} />
                                <ChecklistTabs
                                    tab={tab}
                                    setTab={setTab}
                                    amountProductTypes={data.productTypeChecklists.length}
                                />
                                <ChecklistViewer
                                    expCtx={data.expCtx}
                                    list={currList}
                                    lists={tab === 'product' ? data.productTypeChecklists : undefined}
                                    onChangeList={setCurrList}
                                    applyModelLabels={applyModelLabels}
                                />
                            </div>
                        ) }
                    </>
                ) }
                { err && (
                    <Box mt="2">
                        <Box p="1">Oh no!</Box>
                        <Box p="1">{ err }</Box>
                    </Box>
                ) }
            </div>
        </div>
    )
}