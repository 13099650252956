import React from 'react';

interface Props {
    readonly setApplyModelLabels:(x:boolean) => void;
    readonly applyModelLabels:boolean;
    readonly onExportCsv:() => void;
    readonly onPrint:() => void;
}
export const TopRightBtnArea = ({ onExportCsv, onPrint, applyModelLabels, setApplyModelLabels }:Props) => {
    return (
        <div className="rxp-flex-vcenter-only">
            <label className="rxp-flex-vcenter-only" style={{ cursor:'pointer' }}>
                <input
                    type="checkbox"
                    className="rxp-checkbox"
                    checked={applyModelLabels}
                    onChange={x => setApplyModelLabels(x.target.checked) }
                />
                <span style={{ paddingLeft:'4px', color: 'var(--vsFaded)' }}>Model Labels</span>
            </label>
            &nbsp;
            <button className="rxp-button" onClick={onExportCsv}>Export CSV</button>
            &nbsp;
            <button className="rxp-button" onClick={onPrint}>Print</button>
        </div>
    )
}