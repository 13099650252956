import { ChecklistsAndModel } from './types';
import { downloadCsvFile } from './download-csv-file';
import { GQL } from 'market-dto';
import { Exp } from 'mui';

const getRows = (list:GQL.Checklist, expCtx:Exp.ExpContext, applyModelLabels:boolean) => {
    const rows = list.items.map((item, n) => {
        const exps = item.expressions.map(expStr => {
            const parsed = Exp.parseExpression(expStr, expCtx);
            return Exp.expNodeToStr({ node: parsed.node, expCtx, applyModelLabels });
        })
        const pres = (item.preconditions ?? []).map(expStr => {
            const parsed = Exp.parseExpression(expStr, expCtx);
            return Exp.expNodeToStr({ node: parsed.node, expCtx, applyModelLabels });
        })
        const concs = Object.entries(item.conclusion ?? {}).map(([ key, val ]) => `${key} : ${String(val)}`);
        const elseConcs = Object.entries(item.elseConclusion ?? {}).map(([ key, val ]) => `${key} : ${String(val)}`);
        return {
            id: item.id,
            description: item.description ?? '',
            exp: exps.join('\n\r'),
            pre: pres.join('\n\r'),
            conc: concs.join('\n\r'),
            elseConc: elseConcs.join('\n\r')
        }
    })
    return [
        ['ID', 'Description', 'Preconditions', 'Conditions', 'Conclusion', 'Else Conclusion'],
        ...rows.map(x => [x.id, x.description, x.pre, x.exp, x.conc, x.elseConc])
    ]
}

export const exportListsAsCsv = (data:ChecklistsAndModel, applyModelLabels:boolean) => {
    const { displayParams, hardstop, productTypeChecklists, programChecklist, model } = data;
    const expCtx = Exp.flatJsonSchemaToExpCtx(model.schema, true);

    // these arrays can be confusing. probably shoulda just did a bunch of .push() calls instead.
    const productTypeRows = productTypeChecklists.map((list, n) => {
        return [
            ...n > 0 ? [['']] : [], // adds an empty row between lists!
            ['Product Type', list.name],
            ...list.description ? [[list.description]] : [],
            ...getRows(list, expCtx, applyModelLabels)
        ]
    }).flat();

    const rows = [
        ...Object.entries(displayParams),
        [''],
        ['Hard Stop', hardstop.name],
        ...hardstop.description ? [[hardstop.description]] : [],
        ...getRows(hardstop, expCtx, applyModelLabels),
        [''],
        ['Product Group', programChecklist.name],
        ...programChecklist.description ? [[programChecklist.description]] : [],
        ...getRows(programChecklist, expCtx, applyModelLabels),
        [''],
        ...productTypeRows
    ]
    downloadCsvFile(rows, 'rules.csv');
}