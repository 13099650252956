import { getApolloClient } from "../../client";
import { gql } from '@apollo/client';

const HANDSHAKE = gql`mutation { handshake_checklist }`;

export const handshakeChecklist = async (token:string) => {
    const client = getApolloClient(token);
    try {
        const result = await client.mutate({ mutation:HANDSHAKE as any });
        return result.data?.handshake_checklist;
    } catch {
        // just return undefined...we don't need the error for now.
        return;
    }    
}
