import React from 'react';
import { HighlightText, Exp } from 'mui';
import { format } from 'market-dto';

interface ExpNodeToHtmlStringProps {
    readonly node:Exp.ExpNode|null;
    readonly expCtx:Exp.ExpContext;
    readonly applyModelLabels?:boolean;
    readonly maxWidth:"100%"|number;
    readonly fontSize:number,
    readonly cssPrefix?:string;
    readonly filterText:string;
}
export const RenderExpression = ({
    node,
    expCtx,
    applyModelLabels,
    maxWidth,
    fontSize,
    cssPrefix = "rxp-",
    filterText
}:ExpNodeToHtmlStringProps):React.ReactElement => {

    if (node === null) return <></>;

    const toCss = (css:string, warn?:boolean) => {
        const arr = [cssPrefix + css];
        if (warn) arr.push(cssPrefix + 'warn');
        return arr.join(' ');
    }

    const fn = (x:Exp.GenericWalkerOpts<React.ReactElement>):React.ReactElement => {
        const { acc, node, childAcc, parent } = x;
        if (node === null) return <></>;

        switch (node.type) {
            case 'ParenScopedNode': {
                const warn = !node.endParenFound;
                return <>
                    {acc}
                    <div className={toCss("paren", warn)}>(</div>
                    {childAcc}
                    <div className={toCss("paren", warn)}>)</div>
                </>
            }
            case 'ExpressionNode': {
                const warn = !node.isValidOp || Boolean(node.warning);
                return <>
                    {acc}
                    <div className={toCss("operator", warn)}>{node.op === Exp.EXP_INVALID_CHAR ? node.rawOp : node.op }</div>
                    {childAcc}
                </>
            }
            case 'FunctionCallNode': {
                const warn = !node.isValidFnName || !node.endParenFound || Boolean(node.warning);
                return <>
                    {acc}
                    <div className={toCss("function-name", warn)}>
                        <HighlightText txt={String(node.fnName)} hiTxt={filterText} hiCss={cssPrefix + 'hi'} /><span className={toCss("paren")}>(</span>
                    </div>
                    {childAcc}
                    <div className={toCss("paren")}>)</div>
                </>
            }
            case 'ArrayNode': {
                const warn = !node.endBracketFound;
                return <>
                    {acc}
                    <div className={toCss("array-bracket", warn)}>[</div>
                    {childAcc}
                    <div className={toCss("array-bracket", warn)}>]</div>
                </>
            }
            case 'StringConstantNode': {
                const warn = !node.endQuoteFound;
                const withoutQuotes = node.value.replace(/^\'(.+)\'$/, "$1");
                return <>
                    {acc}
                    <div className={toCss("string-constant", warn)}>
                        <span className={toCss("quote-separator")}>'</span><HighlightText txt={withoutQuotes} hiTxt={filterText} hiCss={cssPrefix + 'hi'} /><span className={toCss("quote-separator")}>'</span>
                    </div>
                </>
            }
            case 'ConstantNode': {
                const warn = node.value === Exp.EXP_INVALID_CHAR;

                if (applyModelLabels && parent && parent.type === 'ExpressionNode') {
                    if (parent.left.type === 'IdentifierNode') {
                        const strVal = String(parent.left.value);
                        const field = expCtx.fieldByName[strVal];
                        if (field?.schema.modelType === 'currency') {
                            return <>
                                {acc}
                                <div className={toCss("numeric-constant", warn)}>{node.value === Exp.EXP_INVALID_CHAR ? node.raw : <HighlightText txt={ format.toCurrencyWithCents(Number(node.value)) } hiTxt={filterText} hiCss={cssPrefix + 'hi'} />}</div>
                            </>
                        } else if (field?.schema.modelType === 'percentage') {
                            const displayVal = Math.round(Number(node.value) * 100) +'%';
                            return <>
                                {acc}
                                <div className={toCss("numeric-constant", warn)}>{node.value === Exp.EXP_INVALID_CHAR ? node.raw : <HighlightText txt={displayVal} hiTxt={filterText} hiCss={cssPrefix + 'hi'} />}</div>
                            </>
                        }
                    }
                }
                return <>
                    {acc}
                    <div className={toCss("numeric-constant", warn)}>{node.value === Exp.EXP_INVALID_CHAR ? node.raw : <HighlightText txt={String(node.value)} hiTxt={filterText} hiCss={cssPrefix + 'hi'} />}</div>
                </>
            }
            case 'IdentifierNode': {
                const warn = !node.isValid;
                const strVal = String(node.value);
                const displayVal = node.value === Exp.EXP_INVALID_CHAR
                    ? node.raw 
                    : applyModelLabels ? (expCtx.fieldByName[strVal]?.label ?? strVal) : strVal;
                return <>
                    {acc}
                    <div className={toCss("model-variable", warn)}>{node.value === Exp.EXP_INVALID_CHAR ? node.raw : <HighlightText txt={displayVal} hiTxt={filterText} hiCss={cssPrefix + 'hi'} />}</div>
                </>
            }
            case 'UnaryNode': {
                // for things like:  -8
                // that "-" is a unary operator
                return <>
                    <div className={toCss("unary-operator")}>{node.op}</div>
                    {acc}
                </>
            }
            case 'ListItemNode': {
                const warn = !node.hasValidPrepend;
                return <>
                    {acc}
                    {node.prepend ? <div className={toCss("comma-separator", warn)}>{node.prepend}</div> : ''}
                    {childAcc}
                </>
            }
        }
        return <></>;
    }

    const result = Exp.genericWalker({
        parent: null,
        node,
        fn,
        acc: <></>,
        initChildAcc: <></>,
        lvl: 0        
    })

    return (
        <div
            className={toCss("root")}
            style={{
                width: maxWidth === '100%' ? '100%' : maxWidth + 'px',
                fontSize: fontSize + 'px'
            }}
        >{result}</div>
    )
}
