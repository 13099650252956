import React from 'react';
import { PrettyExpression } from './pretty-expression';
import { ExtendedCheckItem } from './types';
import { Exp, HighlightText } from 'mui';

interface Props {
    readonly item:ExtendedCheckItem;
    readonly filterText:string;
    readonly expCtx:Exp.ExpContext;
    readonly n:number;
    readonly applyModelLabels:boolean;
}
export const RuleTableRow = ({ applyModelLabels, item, expCtx, filterText, n }:Props) => {

    const { expressions, description } = item;
    const preconditions = item.preconditions ?? [];
    const conclusionExists = item.conclusion || item.elseConclusion;
    const precondBorder = preconditions.length > 0 && (expressions.length > 0 || conclusionExists);
    const conclusionBorder = conclusionExists && (preconditions.length > 0 || expressions.length > 0);

    const conclusions = Object.entries(item.conclusion ?? {}).map(([ field, val ]) => {
        return {
            field,
            val
        }
    })
    const elseConclusions = Object.entries(item.elseConclusion ?? {}).map(([ field, val ]) => {
        return {
            field,
            val
        }
    })

    return (
        <tr style={{ pageBreakInside: 'avoid' }}>
            <td style={{ width:'44px' }}>
                <div className="rxp-padded">
                    <HighlightText
                        hiCss="rxp-hi"
                        hiTxt={filterText}
                        txt={item.id}
                    />
                </div>
            </td>
            <td>
                { description && (
                    <>
                        <div className="rxp-padded">
                            <div className="rxp-small-label">Description</div>
                            { description }
                        </div>
                        <div className="rxp-border-bar"></div>
                    </>
                ) }
                
                { preconditions.length > 0 && (
                    <div className="rxp-padded">
                        <div className="rxp-small-label">Precondition{ preconditions.length > 1 ? 's' : '' }</div>
                        { preconditions.map((expStr, expIndex) => (
                            <PrettyExpression
                                key={'item'+ item.orderNo + 'pre' + expIndex}
                                applyModelLabels={applyModelLabels}
                                filterText={filterText}
                                pad={expIndex > 0}
                                expCtx={expCtx}
                                str={expStr}                                                
                            />
                        )) }
                    </div>
                ) }

                { precondBorder && (
                    <div className="rxp-border-bar"></div>
                ) }
                
                { item.expressions.length > 0 && (
                    <div className="rxp-padded">
                        <div className="rxp-small-label">Expression{ item.expressions.length > 1 ? 's' : '' }</div>
                        { item.expressions.map((expStr, expIndex) => (
                            <PrettyExpression
                                key={'item'+ item.orderNo + 'exp' + expIndex}
                                applyModelLabels={applyModelLabels}
                                filterText={filterText}
                                expCtx={expCtx}
                                str={expStr}
                            />
                        )) }
                    </div>
                ) }

                { conclusionBorder && (
                    <div className="rxp-border-bar"></div>
                ) }

                { conclusionExists && (
                    <div className="rxp-padded">

                        { conclusions.length > 0 && (
                            <>
                                <div className="rxp-small-label">Conclusion{ conclusions.length > 1 ? 's' : '' }</div>
                                { conclusions.map((x, conclusionIndex) => (
                                    <div
                                        key={'item' + item.orderNo + 'conclusion' + conclusionIndex}
                                        className="rxp-flex-vcenter-only"
                                    >
                                        <div className="rxp-model-variable">
                                            <HighlightText
                                                hiCss="rxp-hi"
                                                hiTxt={filterText}
                                                txt={x.field}
                                            />
                                        </div>
                                        &nbsp;
                                        <span className="rxp-arrow-right">→</span>
                                        &nbsp;
                                        <HighlightText
                                            hiCss="rxp-hi"
                                            hiTxt={filterText}
                                            txt={String(x.val)}
                                        />
                                    </div>
                                )) }
                            </>
                        ) }

                        { elseConclusions.length > 0 && (
                            <>
                                <div
                                    className="rxp-small-label"
                                    style={conclusions.length > 0 ? { marginTop:'2px' } : undefined}
                                >Else Conclusion{ elseConclusions.length > 1 ? 's' : '' }</div>
                                { elseConclusions.map((x, conclusionIndex) => (
                                    <div
                                        key={'item' + item.orderNo + 'else-conclusion' + conclusionIndex}
                                        className="rxp-flex-vcenter-only"
                                    >
                                        <div className="rxp-model-variable">
                                            <HighlightText
                                                hiCss="rxp-hi"
                                                hiTxt={filterText}
                                                txt={x.field}
                                            />
                                        </div>
                                        &nbsp;
                                        <span className="rxp-arrow-right">→</span>
                                        &nbsp;
                                        <HighlightText
                                            hiCss="rxp-hi"
                                            hiTxt={filterText}
                                            txt={String(x.val)}
                                        />
                                    </div>
                                )) }
                            </>
                        ) }
                    </div>
                ) }
            </td>
        </tr>
    )

}