import React from 'react';
import { ExtendedCheckItem } from './types';
import { Exp } from 'mui';
import { RuleTableRow } from './rule-table-row';

interface Props {
    readonly items:ExtendedCheckItem[];
    readonly filterText:string;
    readonly expCtx:Exp.ExpContext;
    readonly applyModelLabels:boolean;
}
export const RuleTable = ({ applyModelLabels, items, expCtx, filterText }:Props) => {
    return (
        <table className="rxp-table">
            <tbody>
                { items.map((item, n) => {
                    return (
                        <RuleTableRow
                            n={n}
                            key={item.orderNo}
                            expCtx={expCtx}
                            filterText={filterText}
                            applyModelLabels={applyModelLabels}
                            item={item}
                        />
                    )
                }) }
            </tbody>
        </table>
    )
}