import { saveAs } from 'file-saver';

export const downloadCsvFile = (rows:string[][], filename:string) => {
    const csvText = rows.map(cols => {
        return cols.map(col => {
            return '"' + col.replace(/"/g, '""') + '"';
        }).join(',');
    }).join('\n');
    const blob = new Blob([csvText], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, filename);
}