import React from 'react';

interface Props {
    readonly keyValuePairs:{[x:string]:string|number}
}
export const ChecklistDetails = ({ keyValuePairs }:Props) => {
    return (
        <div style={{ marginBottom: '16px' }}>
            { Object.entries(keyValuePairs).map(([ key, val ]) => (
                <div key={key} className="rxp-flex-vcenter-only">
                    <div className="rxp-label" style={{ flex:1, textAlign:'right', paddingRight: '2px' }}>{ key }:</div>
                    <div className="rxp-title" style={{ flex:1 }}>{ val }</div>
                </div>
            ))}            
        </div>
    )
}

// Hard Stop
// Product Group
// Product Type
