import React, { useState, useEffect } from 'react';
import { Exp } from 'mui';
import { RenderExpression } from './render-expression';

interface Props {
    readonly str:string;
    readonly expCtx:Exp.ExpContext;
    readonly pad?:boolean;
    readonly filterText:string;
    readonly applyModelLabels:boolean;
}
export const PrettyExpression = ({ applyModelLabels, str, pad, filterText, expCtx }:Props) => {

    // ok, you might ask, why the dangerouslySetInnerHTML ?
    // well, i didn't wanna tie the expression renderer to react.
    // at first, this was gonna be vanilla js.

    const [ parsed ] = useState(() => {
        // in a useState init to ensure it's only run once.
        return Exp.parseExpression(str, expCtx);
    })
    
    return (
        <div style={{ paddingTop: pad ? '4px' : undefined }}>
            <RenderExpression
                expCtx={expCtx}
                filterText={filterText}
                applyModelLabels={applyModelLabels}
                fontSize={16}
                maxWidth="100%"
                node={parsed.node}
            />
        </div>
    )
}