import React from 'react';
import { Exp } from 'mui';
import { ChecklistViewer } from './checklist-viewer';
import { RuleTable } from './rule-table';
import { GQL } from 'market-dto';

interface Props {
    readonly label:string;
    readonly list:GQL.Checklist;
    readonly expCtx:Exp.ExpContext;
    readonly applyModelLabels:boolean;
}
export const PrintView = ({ list, label, expCtx, applyModelLabels }:Props) => {
    return (
        <div style={{ paddingBottom: '8px' }}>
            <div className="rxp-print-section-header">
                <div className="rxp-flex">
                    <div className="rxp-print-list-type">{ label }</div>
                    <div className="rxp-print-list-dash">-</div>
                    <div className="rxp-print-list-name">{ list.name }</div>
                </div>
                { list.description && <div className="rxp-print-list-desc">{ list.description }</div> }
            </div>

            <RuleTable
                expCtx={expCtx}
                items={list.items.map((item, n) => {
                    return {
                        ...item,
                        orderNo: n+1
                    }
                })}
                filterText=""
                applyModelLabels={applyModelLabels}
            />
        </div>
    )
}