import { useEffect, useState } from 'react';
import { useStore } from 'mui';
import { store, urls } from 'services';
import { handshakeChecklist } from 'api';
import { QStringProps } from '../types';

export const useServiceDiscovery = () => {

    const [ srvDsc, setSrvDsc ] = useStore<store.ServiceDiscovery|null>(store.keys.serviceDiscovery, null);
    const [ fetching, setFetching ] = useState(true);
    const [ serviceDiscoveryError, setServiceDiscoveryError ] = useState('');
    const [ qstringParams, setQStringParam ] = useState<QStringProps>()

    const getServiceDiscovery = async (instance:string):Promise<store.ServiceDiscovery|null> => {
        const url = urls.getServiceDiscoveryUrl() + '?instance=' + encodeURIComponent(instance);
        try {
            const response = await fetch(url);
            if (response) {
                const result = await response.json();
                return result;
            } else {
                return null;
            }
        } catch {
            return null;
        }
    }

    const check = async (params:QStringProps) => {
        const result = await getServiceDiscovery(params.instance);
        if (result && typeof result === 'object' && result['apiUrl']) {
            setSrvDsc(result); // now api calls can be made!
            setFetching(false);
        } else {
            setServiceDiscoveryError('Unable to reach service discovery.');
            setFetching(false);
        }
    }

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        window.history.pushState({}, document.title, "/"); // clear it.
        if (!params.view || !params.token || !params.instance) {
            // missing params
            setServiceDiscoveryError('A required querystring parameter was missing.');
            setFetching(false);
        } else {
            setQStringParam({ view:params.view, instance:params.instance, token:params.token });
            check(params as any);
        }
    }, [])

    return {
        qstringParams,
        serviceDiscoveryError,
        fetching
    }
}
