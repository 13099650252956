import React from 'react';
import { NotFound, ChecklistView, Error } from './views';
import { useServiceDiscovery } from 'hooks';
import 'mui/dist/mui.css'; // This is one special thing you must do.

export const App = () => {

    const { fetching, qstringParams, serviceDiscoveryError } = useServiceDiscovery();
    const { view, token } = qstringParams ?? {};

    if (fetching) return <></>; // show nothing while doing our handshake.

    // For now, do not distinguish between errors
    if (serviceDiscoveryError) return <Error err={serviceDiscoveryError} />;
    if (!token) return <Error err="Missing token" />;    

    switch (view) {
        case "checklist": return <ChecklistView token={token} />;
        default: return <NotFound />;
    }
}